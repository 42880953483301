import { render, staticRenderFns } from "./add-product.vue?vue&type=template&id=15f87e4a&scoped=true"
import script from "./add-product.vue?vue&type=script&lang=js"
export * from "./add-product.vue?vue&type=script&lang=js"
import style0 from "./add-product.vue?vue&type=style&index=0&id=15f87e4a&prod&scoped=true&lang=css"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@riophae/vue-treeselect/dist/vue-treeselect.min.css?vue&type=style&index=2&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f87e4a",
  null
  
)

export default component.exports